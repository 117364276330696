<template>
  <div id="about">
    When you have a great story about how your product or service was built to change lives, share it. The "About Us" page is a great place for it to live, too. Good stories humanize your brand, providing context and meaning for your product. What’s more, good stories are sticky -- which means people are more likely to connect with them and pass them on.
    <div @click="change()">
      let's go
    </div>
  </div>
</template>

<script>
export default {
  name: 'About',
  methods:{
    change(){
      this.$router.push({ //核心语句
        path:'/index', //跳转的路径
        // query:{           //路由传参时push和query搭配使用 ，作用时传递参数
        //   id:this.id , 
        // }
      })
    }
  }
}
</script>
<style>
#about {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>