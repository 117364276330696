<template>
  <div id="app">
    <header>
      <a-row id="navWrap" class="navWrap">
        <a-col :span="7">
          <div class="logo" @click="toIndex()">
            <img src="@/assets/logo.png" alt="">
          </div>
        </a-col>
        <a-col :span="12" :offset="1">
          <div class="navList">
            <router-link to="/health">
              <p data-link="health">
                智慧健康管理
              </p>
            </router-link>
            <router-link to="/sterilize">
              <p data-link="sterilize">
                智能消毒杀菌
              </p>
            </router-link>
            <router-link to="/hospital">
              <p data-link="hospital">
                医院业务智能化
              </p>
            </router-link>
            <router-link to="/newsCenter">
              <p data-link="news">
                新闻中心
              </p>
            </router-link>
            <router-link to="/companyProfile">
              <p data-link="companyProfile">
                关于我们
              </p>
            </router-link>

            <!-- <router-link to="/contactUs">
              <p data-link="contactUs">
                职业发展
              </p>
            </router-link> -->
          </div>
        </a-col>
      </a-row>
    </header>

    <div>
      <router-view :key="$route.fullPath" />
    </div>

    <footer v-if="notIndex($route.fullPath)">
      <div class="companyInfo">
        <div class="left">
          <img src="@/assets/footerLogo.png" alt="">
          <div class="content">
            <router-link to="/companyProfile">
              <p>
                关于我们
              </p>
            </router-link>
            <p>|</p>
            <p style="cursor: pointer;" @click="jumpOutside()">
              网上商城
            </p>
            <!-- <p>|</p>
            <a href="http://www.usopptech.com/download.html" target="_blank">
              <p style="cursor: pointer;">
                软件下载
              </p>
            </a> -->
          </div>
        </div>
        <div class="center">
          <h2>{{ info.name }}</h2>
          <p>电话：{{ info.phone }}</p>
          <p>邮箱：{{ info.email }}</p>
          <p>地址：{{ info.address }}</p>
        </div>
        <div class="right">
          <div class="appLeft">
            <img :src="info.qrCodeUrl" alt="">
            <p>微信公众号</p>
          </div>
          <div class="appRight">
            <img src="@/assets/appQrcode.jpg" alt="">
            <p>软件下载</p>
          </div>
        </div>
      </div>
      <!-- <p class="line" /> -->
      <div class="recordInfo">
        <p>{{ info.name }}</p>
        <a target="_blank" href="https://beian.miit.gov.cn">
          <p>京ICP备17066860号</p>
        </a>
        <div class="icpWrap">
          <img src="@/assets/icp.png" alt="">
          <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802025206">
            <p>京公网安备 11010802025206号</p>
          </a>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      info: {},
      appUrl: 'm.usopptech.com', // 移动端页面地址
      webUrl: 'www.usopptech.com', // web端页面地址
      webUrl2: 'usopptech.com', // web端页面地址
    }
  },
  mounted() {
    // 控制导航栏滚动背景
    window.addEventListener('scroll', function () {
      var scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      if (scrollTop > 100) {
        document.getElementById('navWrap').style.background = 'rgba(0, 0, 0, 0.6)'
      } else {
        document.getElementById('navWrap').style.background = 'none'
      }
    })
    if (this._isMobile()) {
      console.log('手机端')
      const allUrl = window.location.href
      let url = ''
      if (allUrl.indexOf(this.webUrl) !== -1) {
        url = window.location.href.replace(this.webUrl, this.appUrl)
      } else {
        url = window.location.href.replace(this.webUrl2, this.appUrl)
      }

      window.location.replace(url) // 跳转到移动端对应页面
    } else {
      console.log('pc端')
    }
    this.$router.afterEach((to, from, next) => {
      window.scrollTo(0, 0)
    })

    this.$post('/api/pc/common/get/company/info').then((res) => {
      //  获取公司信息
      console.log(res)
      this.info = res
    })
  },
  methods: {
    // 判断是否首页
    notIndex(path) {
      if (path == '/index') {
        return false
      }
      return true
    },
    // 跳转到首页
    toIndex() {
      this.$router.push({
        //核心语句
        path: '/index', //跳转的路径
      })
    },
    jumpType(id, name) {
      this.$router.push({
        //核心语句
        path: '/productCategory', //跳转的路径
        query: { id, name },
      })
    },
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
      return flag
    },
    jumpOutside() {
      window.open(this.info.onlineMall, '_blank')
    },
  },
}
</script>
<style lang='less' scoped>
#app {
  min-width: 100%;
  // min-width: 1903px;
  min-height: 100%;
}
.navWrap {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
}
.navWrap .logo {
  height: 1rem;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.navWrap .logo img {
  width: 1.86rem;
  height: 0.59rem;
  margin-left: auto;
}
.navList {
  height: 0.97rem;
  line-height: 0.97rem;
  display: flex;
  justify-content: space-around;
  cursor: pointer;
  font-size: 0.18rem;
  color: #ddddde;
}
.navList a {
  color: #ddddde;
  position: relative;
  display: block;
  content: '';
  :after {
    width: 100%;
    height: 0.03rem;
    background: red;
  }
}
.navList a:hover {
  border-bottom: 0.03rem solid #0086cd;
}

.navList a.active {
  border-bottom: 0.03rem solid #0086cd;
}

footer {
  width: 100%;
  height: 4.6rem;
  background-color: #262d35;
}

footer .companyInfo {
  display: flex;
  color: #617082;
  font-size: 0.16rem;
  margin: 0 auto;
  justify-content: space-between;
  padding: 1.02rem 3.6rem;
  border-bottom: 0.01rem solid #617082;
}
footer .companyInfo .left {
  width: 2.75rem;
  a {
    color: #617082;
  }
}

footer .companyInfo .left img {
  width: 2.28rem;
  height: 0.72rem;
  margin: 0 auto;
  display: block;
  margin-bottom: 0.46rem;
}
footer .companyInfo .left .content {
  display: flex;
  justify-content: space-evenly;
  p {
    margin: 0;
  }
}

// footer .companyInfo .center {
//   margin-left: 1.88rem;
// }
footer .companyInfo .center h2 {
  font-size: 0.2rem;
  color: #e6edf6;
  margin-bottom: 0.24rem;
}
footer .companyInfo .center p {
  margin-bottom: 0.1rem;
}

footer .companyInfo .right {
  text-align: center;
  font-size: 0.14rem;
  display: flex;
  margin-top: 0.12rem;
  color: #e5ecf5;
}
footer .companyInfo .right img {
  width: 0.85rem;
  height: 0.85rem;
  margin-bottom: 0.12rem;
}
footer .companyInfo .appRight {
  margin-left: 0.24rem;
  text-align: center;
  font-size: 0.14rem;
}
footer .companyInfo .appRight img {
  width: 0.85rem;
  height: 0.85rem;
  margin-bottom: 0.12rem;
}
footer .line {
  height: 0.01rem;
  background-color: #ffffff;
}
footer .icpWrap {
  display: flex;
  align-items: center;
}
footer .icpWrap img {
  width: 0.2rem;
  height: 0.2rem;
}

footer .recordInfo {
  display: flex;
  font-size: 0.16rem;
  justify-content: space-between;
  color: #617082;
  margin-top: 0.32rem;
  padding: 0 4.45rem;
  a {
    color: #617082;
  }
}
footer a {
  color: #ffffff;
}
footer .recordInfo p {
  margin: 0;
}

.ant-dropdown-menu-item {
  padding: 0.2rem 0.3rem;
}
</style>
