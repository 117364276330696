<template>
  <div id="productDetails">
    <a-spin :spinning="bannerSpinning||infoSpinning">
      <div class="banner">
        <img
          :src="banner"
          alt=""
        >
      </div>

      <!-- <div class="pageInfo">
        <div class="titleWrap">
          <img
            src="@/assets/pagePosition.png"
            alt=""
          >
          <p>您当前位置：首页 > 产品中心 > {{ info.title }} > <span>产品详情</span></p>
        </div>
        <div
          class="returnWrap"
          @click="toList()"
        >
          <img
            src="@/assets/return.png"
            alt=""
          >
          <p>返回产品中心</p>
        </div>
      </div> -->

      <div class="line" />

      <div class="contentWrap">
        <h2 class="title">
          {{ info.title }}
        </h2>
        <p class="time">
          {{ info.newsDate }}
        </p>
        <div
          v-for="item of info.headerVideo"
          :key="item.videoKey"
          class="videoList"
        >
          <div
            :id="item.videoKey"
            class="video"
          />
          <h2 class="videoTitle">
            {{ item.title }}
          </h2>
        </div>
        <div
          class="ql-editor"
          v-html="info.content"
        />
        <div
          v-for="item of info.footerVideo"
          :key="item.videoKey"
          class="videoList"
        >
          <div
            :id="item.videoKey"
            class="video"
          />
          <h2 class="videoTitle">
            {{ item.title }}
          </h2>
        </div>
      </div>
    </a-spin>
  </div>
</template>

<script>
import Player from 'xgplayer'
import '../../xg/.xgplayer/skin/index.js'
import { quillEditor } from 'vue-quill-editor' //调用编辑器
import 'quill/dist/quill.snow.css'
export default {
  name: 'ProductDetails',
  data: function () {
    return {
      bannerSpinning: true,
      infoSpinning: true,
      info: {},
      banner: '',
    }
  },
  mounted() {
    const id = this.$route.query.id
    this.$post('/api/pc/product/info', {
      id,
    }).then((res) => {
      // 获取详情
      console.log(res)
      this.info = res
      this.infoSpinning = false
      setTimeout(() => {
        res.headerVideo.forEach((item) => {
          console.log(`v${item.videoKey}`)
          new Player({
            id: item.videoKey,
            url: item.videoUrl,
            poster: item.coverUrl,
            width: 560,
            height: 378,
          })
        })

        res.footerVideo.forEach((item) => {
          new Player({
            id: item.videoKey,
            url: item.videoUrl,
            poster: item.coverUrl,
            width: 560,
            height: 378,
          })
        })
      }, 500)
    })
    this.$post('/api/pc/common/get/banner').then((res) => {
      // 获取banner
      this.banner = res.productInfo.pcImgUrl
      this.bannerSpinning = false
      console.log(res)
    })
  },
  methods: {
    toList() {
      this.$router.push({
        //核心语句
        path: '/productCenter', //跳转的路径
      })
    },
  },
}
</script>
<style lang="less" scoped>
.ql-editor {
  line-height: 2;
}
p {
  margin: 0;
}
.banner {
  min-height: 3rem;
  img {
    width: 100%;
    height: 100%;
  }
}

.pageInfo {
  padding: 0.3rem 2.18rem;
  font-size: 0.16rem;
  line-height: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .titleWrap {
    display: flex;
    img {
      margin-right: 0.06rem;
    }
    p {
      margin: 0;
    }
    span {
      color: #0d51a4;
    }
  }
  .returnWrap {
    font-size: 0.18rem;
    color: #0d51a4;
    display: flex;
    align-items: center;
    cursor: pointer;
    img {
      margin-right: 0.13rem;
    }
  }
}
.line {
  height: 0.01rem;
  background-color: #ececec;
}

.videoList {
  margin-bottom: 0.2rem;
  text-align: center;
  .video {
    margin: 0 auto;
  }
}

.contentWrap {
  margin: 0.6rem 3.6rem 2.36rem;
  .title {
    font-size: 0.48rem;
    text-align: center;
  }
  /deep/img {
    max-width: 100%;
  }
}
</style>