<template>
  <div
    id="health"
    :style="imgBanner"
  >
    <a-spin :spinning="spinning">
      <div class="banner">
        <div id="mse" />
      </div>

      <!-- 解决方案 -->
      <div class="programmeWrap">
        <div class="titleWrap">
          <!-- <img src="@/assets/leftLine.png" alt=""> -->
          <h2>解决方案</h2>
          <p>乌索普健康管理服务系统</p>
          <!-- <img src="@/assets/rightLine.png" alt=""> -->
        </div>
        <img
          class="programmeImg"
          src="@/assets/programmeImg.png"
          alt=""
        >
        <!-- <img class="programmeImg" :src="info.solutionUrl" alt=""> -->
        <p class="content">
          {{ info.solutionInstruction }}
        </p>
      </div>

      <!-- 产品介绍 -->
      <div class="productWrap">
        <div class="titleWrap">
          <!-- <img src="@/assets/leftLine.png" alt=""> -->
          <h2>产品介绍</h2>
          <!-- <img src="@/assets/rightLine.png" alt=""> -->
        </div>
        <div class="productList">
          <div
            v-for="item of info.detail"
            :key="item.id"
            class="item"
            :style="{backgroundImage:'url('+item.imgUrl+')'}"
            @click="jumpType(item.jumpUrl,id)"
          />
        </div>
      </div>
    </a-spin>
  </div>
</template>

<script>
import Player from 'xgplayer'
import '../../xg/.xgplayer/skin/index.js'

export default {
  name: 'Health',
  data() {
    return {
      info: {},
      spinning: true,
      imgBanner: {
        backgroundImage: 'url(' + require('../assets/healthBg.jpg') + ')',
      },
    }
  },
  mounted() {
    this.$post('/api/pc/health/manage/get').then((res) => {
      // 获取智慧健康管理
      this.info = res
      new Player({
        id: 'mse',
        url: res.videoUrl,
        poster: res.coverUrl,
        width: 1225,
        height: 689,
      })
      this.spinning = false
    })
  },
  methods: {
    jumpType(url, id) {
      if (url) {
        window.location = url
        return
      }
      this.$router.push({
        //核心语句
        path: '/productCategory', //跳转的路径
        query: { id },
      })
    },
  },
}
</script>
<style lang='less' scoped>
p,
h2 {
  margin: 0;
  color: #fff;
}
#health {
  background-size: 100% 100%;
  padding-top: 2.02rem;
}
.banner {
  background-size: 100% 100%;
}
#mse {
  width: 8.01rem !important;
  height: 4.5rem !important;
  margin: 0 auto;
  background: #fff;
}
.programmeWrap {
  .titleWrap {
    margin: 1.48rem 3.6rem 0;
    justify-content: space-between;
    align-items: center;
    h2 {
      font-size: 0.48rem;
    }
    p {
      font-size: 0.36rem;
    }
  }
  .programmeImg {
    width: 100%;
    height: auto;
    margin-top: 1.06rem;
    padding: 0 3.68rem;
  }
  .content {
    margin: 1.18rem 3.6rem 0.74rem;
    text-align: justify;
    font-size: 0.22rem;
    line-height: 1.8;
  }
}
.productWrap {
  .titleWrap {
    margin: 0 3.6rem 1.02rem;
    justify-content: space-between;
    align-items: center;
    h2 {
      font-size: 0.48rem;
    }
  }
  .productList {
    display: flex;
    flex-wrap: wrap;
    padding: 0 4.76rem 1.95rem;
    justify-content: space-between;
    .item {
      margin-bottom: 0.49rem;
      width: 4.45rem;
      height: 3.1rem;
      background-size: 100% 100%;
      border-radius: 0.2rem;
      cursor: pointer;
    }
  }
}
</style>