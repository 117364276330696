<template>
  <div id="hospital">
    <a-spin :spinning="spinning">
      <div class="banner">
        <img
          :src="info.solutionUrl"
          alt=""
        >
      </div>
      <div class="titleWrap">
        <h2>解决方案</h2>
      </div>
      <p class="content">
        {{ info.solutionInstruction }}
      </p>

      <!-- 产品介绍 -->
      <div class="productWrap">
        <div class="titleWrap">
          <h2>产品介绍</h2>
        </div>
        <div class="productList">
          <div
            v-for="item of info.detail"
            :key="item.id"
            class="item"
          >
            <img
              :src="item.imgUrl"
              alt=""
            >
            <p class="content">
              {{ item.abstract }}
            </p>
          </div>
        </div>
      </div>
    </a-spin>
  </div>
</template>

<script>
export default {
  name: 'Hospital',
  data() {
    return {
      info: {},
      spinning: true,
    }
  },
  mounted() {
    this.$post('/api/pc/hospital/business/get').then((res) => {
      // 获取医院智能化信息
      this.info = res
      this.spinning = false
      console.log(res)
    })
  },
  methods: {
    jumpType(url, id) {
      if (url) {
        window.location = url
        return
      }
      this.$router.push({
        //核心语句
        path: '/productCategory', //跳转的路径
        query: { id },
      })
    },
  },
}
</script>
<style lang='less' scoped>
p,
h2 {
  margin: 0;
}
.banner {
  min-height: 5rem;
  img {
    width: 100%;
    height: 100%;
  }
}
.titleWrap {
  display: flex;
  margin: 0.5rem 3.68rem 0;
  justify-content: space-between;
  align-items: center;
  h2 {
    font-size: 0.48rem;
  }
}
.content {
  margin: 0.5rem 3.6rem;
  text-align: justify;
  font-size: 0.22rem;
  line-height: 1.8;
}
.productWrap {
  .titleWrap {
    display: flex;
    margin: 0 3.68rem;
    justify-content: space-between;
    align-items: center;
    h2 {
      font-size: 0.48rem;
    }
  }
  .productList {
    margin: 0.99rem 3.44rem 0 4.09rem;
    .item {
      margin-bottom: 1rem;
      display: flex;
      img {
        display: block;
        width: 4.78rem;
        height: 3.36rem;
        flex-shrink: 0;
        border-radius: 0.2rem;
      }
      .content {
        margin: 0.71rem 0 0 0.57rem;
        font-size: 0.22rem;
        color: #333333;
      }
    }
  }
}
</style>