<template>
  <div id="newsCenter">
    <a-spin :spinning="bannerSpinning||listSpinning">
      <div class="banner">
        <img
          :src="banner"
          alt=""
        >
      </div>

      <!-- <div class="pageInfo">
        <img
          src="@/assets/pagePosition.png"
          alt=""
        >
        <p>您当前位置：首页 > <span>新闻中心</span></p>
      </div> -->

      <div class="line" />

      <div class="newsList">
        <div
          v-for="item of newsList"
          :key="item.id"
          class="item"
        >
          <img
            class="headImg"
            :src="item.coverUrl"
            alt=""
            @click="toDetails(item.jumpUrl,item.id)"
          >
          <div class="contentWrap">
            <div
              class="title"
              @click="toDetails(item.jumpUrl,item.id)"
            >
              <h2>{{ item.title }}</h2>
              <div class="moreBtn">
                <p>MORE</p>
                <img
                  src="@/assets/moreNews.png"
                  alt=""
                >
              </div>
            </div>
            <p
              class="content"
              @click="toDetails(item.jumpUrl,item.id)"
            >
              {{ item.abstract }}
            </p>
            <p class="date">
              {{ item.monthDay }}
            </p>
            <p class="year">
              {{ item.year }}
            </p>
          </div>
        </div>

        <a-pagination
          v-model="current"
          :total="total"
          :default-page-size="pageSize"
          show-less-items
          @change="getList(current)"
        />
      </div>
    </a-spin>
  </div>
</template>

<script>
export default {
  name: 'NewsCenter',
  data: function () {
    return {
      bannerSpinning: true,
      listSpinning: true,
      banner: '', //banner
      newsList: [],
      total: 1, //总数量
      current: 1, // 页数
      pageSize: 10, // 每页数据量
    }
  },
  mounted() {
    this.$post('/api/pc/common/get/banner').then((res) => {
      // 获取banner
      this.bannerSpinning = false
      this.banner = res.news.pcImgUrl
      console.log(res)
    })
    this.getList(this.current)
  },
  methods: {
    toDetails(url, id) {
      if (url) {
        window.open(url, '_blank')
        return
      }
      this.$router.push({
        //核心语句
        path: '/newsDetails', //跳转的路径
        query: { id },
      })
    },
    getList(page) {
      // 获取新闻列表
      this.$post('/api/pc/news/list', {
        page,
        count: this.pageSize,
      }).then((res) => {
        // 获取新闻列表
        console.log(res)
        this.listSpinning = false
        this.newsList = res.data
        this.total = res.total
      })
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
p {
  margin: 0;
}
.banner {
  min-height: 3rem;
  img {
    width: 100%;
    height: 100%;
  }
}

.pageInfo {
  padding: 0.3rem 2.18rem;
  font-size: 0.16rem;
  line-height: 1;
  display: flex;
  justify-content: flex-start;
}
.pageInfo img {
  margin-right: 0.06rem;
}
.pageInfo p {
  margin: 0;
}
.pageInfo span {
  color: #0d51a4;
}

.line {
  height: 0.01rem;
  background-color: #ececec;
}

.newsList {
  padding: 0.62rem 3.65rem;
  .item {
    display: flex;
    margin-bottom: 0.54rem;
    .headImg {
      width: 3.05rem;
      height: 1.94rem;
      background-color: #0d51a4;
      margin-right: 0.46rem;
      flex-shrink: 0;
      cursor: pointer;
    }
    .contentWrap {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex-grow: 1;
      .title {
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        h2 {
          max-width: 7.5rem;
          font-size: 0.3rem;
          color: #333333;
          margin: 0;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .moreBtn {
          display: flex;
          font-size: 0.18rem;
          align-items: center;
          img {
            width: 0.1rem;
            height: 0.17rem;
            margin-left: 0.1rem;
          }
        }
        .moreBtn:hover {
          color: #0e53a4;
          img {
            content: url('../assets/moreNewsHover.png');
          }
        }
      }
      .content {
        height: 0.81rem;
        font-size: 0.18rem;
        color: #333333;
        cursor: pointer;
      }
      .date {
        font-size: 0.36rem;
        color: #333333;
        line-height: 1;
        text-align: right;
      }
      .year {
        font-size: 0.24rem;
        color: #999999;
        line-height: 1;
        text-align: right;
      }
    }
  }
}
.ant-pagination {
  text-align: center;
}
</style>
