<template>
  <div id="sterilize">
    <a-spin :spinning="spinning">
      <div class="banner">
        <img
          :src="info.solutionUrl"
          alt=""
        >
      </div>
      <div class="titleWrap">
        <h2>解决方案</h2>
      </div>
      <p class="content">
        {{ info.solutionInstruction }}
      </p>

      <!-- 产品介绍 -->
      <div class="productWrap">
        <div class="titleWrap">
          <h2>产品介绍</h2>
        </div>
        <div class="productList">
          <div
            v-for="item of info.detail"
            :key="item.id"
            class="item"
            @click="jumpType(item.jumpUrl,item.id)"
          >
            <img
              :src="item.imgUrl"
              alt=""
            >
            <!-- <h2>{{ item.title }}</h2>
            <p>{{ item.abstract }}</p> -->
          </div>
        </div>
      </div>

      <!-- 应用案例 -->
      <div class="caseWrap">
        <img
          v-if="info.case"
          class="caseImg"
          :src="info.case.imgUrl"
          alt=""
          @click="jumpType(info.case.jumpUrl,null)"
        >
      </div>
    </a-spin>
  </div>
</template>

<script>
export default {
  name: 'Sterilize',
  data() {
    return {
      info: {},
      spinning: true,
    }
  },
  mounted() {
    this.$post('/api/pc/kill/germ/get').then((res) => {
      // 获取智能消杀信息
      this.info = res
      console.log(res)
      this.spinning = false
    })
  },
  methods: {
    jumpType(url, id) {
      if (url) {
        window.location = url
        return
      }
      this.$router.push({
        //核心语句
        path: '/productCategory', //跳转的路径
        query: { id },
      })
    },
  },
}
</script>
<style lang='less' scoped>
p,
h2 {
  margin: 0;
}
.banner {
  min-height: 5rem;
  img {
    height: 100%;
    width: 100%;
  }
}
.titleWrap {
  display: flex;
  margin: 0.5rem 3.68rem 0;
  justify-content: space-between;
  align-items: center;
  h2 {
    font-size: 0.48rem;
  }
}
.content {
  margin: 0.5rem 3.68rem;
  text-align: justify;
  font-size: 0.22rem;
  line-height: 1.8;
}
.productWrap {
  .titleWrap {
    display: flex;
    margin: 0 3.68rem;
    justify-content: space-between;
    align-items: center;
    h2 {
      font-size: 0.48rem;
    }
  }
  .productList {
    margin: 0.97rem auto 0.41rem;
    justify-content: space-between;
    display: flex;
    padding: 0 4.76rem;
    flex-wrap: wrap;
    cursor: pointer;
    .item {
      margin-bottom: 0.85rem;
      background: #fff;
      width: 4.45rem;
      overflow: hidden;
      color: #333333;
      img {
        border-radius: 0.2rem;
        width: 4.45rem;
        height: 3.1rem;
        display: block;
      }
      h2 {
        margin: 0.35rem 0.17rem;
        font-size: 0.3rem;
      }
      p {
        font-size: 0.2rem;
        margin: 0 0.28rem 0 0.17rem;
      }
    }
  }
}

.caseWrap {
  margin-bottom: 1.19rem;
  .titleWrap {
    display: flex;
    margin: 1.02rem 4.5rem 0;
    justify-content: space-between;
    align-items: center;
    h2 {
      font-size: 0.6rem;
    }
    img {
      width: 3.49rem;
      height: 0.18rem;
    }
  }
  .caseImg {
    cursor: pointer;
    width: 100%;
    height: 7.62rem;
  }
}
</style>