<template>
  <div id="contactUs">
    <a-spin :spinning="bannerSpinning||infoSpinning">
      <div class="banner">
        <img
          :src="banner"
          alt=""
        >
      </div>

      <div class="pageInfo">
        <img
          src="@/assets/pagePosition.png"
          alt=""
        >
        <p>您当前位置：首页 > <span>联系我们</span></p>
      </div>

      <div class="line" />

      <div class="companyWrap">
        <div class="">
          <h2 class="companyName">
            {{ info.name }}
          </h2>
          <p class="line" />
          <div class="phoneWrap">
            <img
              src="@/assets/phone.png"
              alt=""
            >
            <div class="phone">
              <p class="title">
                全国服务热线
              </p>
              <p class="phoneNumber">
                {{ info.phone }}
              </p>
            </div>
          </div>
          <div class="info">
            <p class="emaill">
              邮箱：{{ info.email }}
            </p>
            <p class="address">
              地址：{{ info.address }}
            </p>
          </div>
        </div>
        <img
          src="@/assets/contactUs.png"
          alt=""
        >
      </div>
    </a-spin>
  </div>
</template>

<script>
export default {
  name: 'ContactUs',
  data:function() {
  return {
      banner:'',//banner
      info:{},
      bannerSpinning:true,
      infoSpinning:true,
    };
  },
  mounted() {
    this.$post('/api/pc/common/get/banner')
      .then((res) => { // 获取banner
        this.bannerSpinning = false;
        this.banner = res.contactBannerUrl;
        console.log(res)
      })
    this.$post('/api/pc/common/get/company/info')
      .then((res) => { // 获取公司信息
        console.log(res)
        this.infoSpinning = false;
        this.info = res;
      })
  },
  methods:{

  }
}
</script>
<style lang="less" scoped>
    p{
      margin: 0;  
    }
    .banner{
        height: 399px;
        background-color: #006bf0; 
        img{
            width: 100%;
            height: 100%;
        }
    }

    .pageInfo{
        padding: 30px 218px;
        font-size: 16px;
        line-height: 1;
        display: flex;
        justify-content: flex-end;
        img{
            margin-right:6px
        }
        p{
            margin: 0;
        }
        span{
            color: #0d51a4;
        }
    }
    .line{
      height: 1px;
      background-color: #ececec;
    }

    .companyWrap{
      margin: 70px 360px 160px;
      display: flex;
      justify-content: space-between;
      color: #333333;
      .line{
        width: 93px;
        height: 4px;
        margin: 30px 0 56px;
        background-image: linear-gradient(90deg, 
          #0c51a3 0%, 
          #269cd9 100%), 
        linear-gradient(
          #333333, 
          #333333);
        background-blend-mode: normal, 
          normal;
      }
      .phoneWrap{
        display: flex;
        margin-bottom: 34px;
        img{
          width: 75px;
          height: 72px;
          margin-right: 20px;
        }
        .phone{
          display: flex;
          flex-direction: column;
          justify-content: center;
          .title{
            font-size: 18px;
          }
          .phoneNumber{
            font-size: 36px;
          }
        }
      }
      .info{
        font-size: 18px;
        line-height: 40px;
      }
    }
</style>