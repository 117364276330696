import Vue from 'vue';
import App from './App';
import Antd from 'ant-design-vue';
import less from 'less'

import 'ant-design-vue/dist/antd.css';
//导入 vue router
import VueRouter from 'vue-router';

import {http} from './api/network'

Vue.prototype.$post = http.post;
Vue.prototype.$get = http.get;

import index from './components/index';
import about from './components/about';
import health from './components/health';
import sterilize from './components/sterilize';
import hospital from './components/hospital';
import companyProfile from './components/companyProfile';
import newsCenter from './components/newsCenter';
import newsDetails from './components/newsDetails';
import productCenter from './components/productCenter';
import productCategory from './components/productCategory';
import productDetails from './components/productDetails';
import contactUs from './components/contactUs';

Vue.use(less)
Vue.use(Antd);
Vue.use(VueRouter);
Vue.config.productionTip = false;


// 页面配置
const routes = [
  { path: '/', redirect:'/index'},
  { path: '/index', name: 'index', component: index ,meta:{title: '首页'}},
  { path: '/about', name: 'about', component: about },
  { path: '/health', name: 'health', component: health ,meta:{title: '智慧健康管理'}},
  { path: '/sterilize', name: 'sterilize', component: sterilize ,meta:{title: '智能消毒杀菌'}},
  { path: '/hospital', name: 'hospital', component: hospital ,meta:{title: '医院业务智能化'}},
  { path: '/companyProfile', name: 'companyProfile', component: companyProfile ,meta:{title: '关于我们'}},
  { path: '/newsCenter', name: 'newsCenter', component: newsCenter ,meta:{title: '新闻中心'}},
  { path: '/newsDetails', name: 'newsDetails', component: newsDetails ,meta:{title: '新闻详情'}},
  { path: '/productCenter', name: 'productCenter', component: productCenter,meta:{title: '产品市中心'}},
  { path: '/productCategory', name: 'productCategory', component: productCategory ,meta:{title: '产品信息'}},
  { path: '/productDetails', name: 'productDetails', component: productDetails ,meta:{title: '产品详情'}},
  { path: '/contactUs', name: 'contactUs', component: contactUs ,meta:{title: '首页'}},

]
const router = new VueRouter({
  routes,
  linkActiveClass:'active'
})

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  components: { App },
  mounted() {
  	setRem();
  },
  template: '<App/>'
});

function setRem() {
  var whdef = 100/1920;// 表示1920的设计图,使用100PX的默认值
  var bodyWidth = document.body.clientWidth;// 当前窗口的宽度
  var rem = bodyWidth * whdef;// 以默认比例值乘以当前窗口宽度,得到该宽度下的相应FONT-SIZE值
  document.getElementsByTagName('html')[0].style.fontSize = rem + 'px';
}

window.addEventListener('load', setRem);
window.addEventListener('resize', setRem);

