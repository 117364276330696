<template>
  <div id="index">
    <!-- banner图 -->
    <a-spin :spinning="bannerSpinning">
      <div class="banner-swiper-container">
        <div class="swiper-wrapper">
          <div v-for="item of bannerList" :key="item.id" class="swiper-slide">
            <img :src="item.pcImgUrl" alt="" @click="jumpPage()">
          </div>
        </div>
        <!-- Add Pagination -->
        <div class="swiper-pagination" />

        <!-- <div class="pagination">
          <div v-for="(item,i) of bannerList" :key="item.id" :class="i==nowIndex?'itemActive':'item'">
            <div class="line"></div>
            <div class="disc"></div>
          </div>
          <div class="dots-bd-right"></div>
        </div> -->
        <!-- Add Arrows -->
        <div class="swiper-button-next">
          <img src="@/assets/right.png" alt="">
        </div>
        <div class="swiper-button-prev">
          <img src="@/assets/left.png" alt="">
        </div>
      </div>
    </a-spin>

    <div class="bottom">
      <div class="infoWrap">
        <p>电话：{{ info.phone }}</p>
        <p>邮箱：{{ info.email }}</p>
        <a target="_blank" href="https://beian.miit.gov.cn">
          <p>京ICP备17066860号</p>
        </a>
        <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802025206">
          <p>京公网安备 11010802025206号</p>
        </a>
        <p />
        <router-link to="/companyProfile">
          <p>
            关于我们
          </p>
        </router-link>
        <p style="cursor: pointer;" @click="jumpOutside(info.onlineMall)">
          网上商城
        </p>
        <!-- <a href="http://www.usopptech.com/download.html" target="_blank">
          <p style="cursor: pointer;">
            软件下载
          </p>
        </a> -->
        <div class="qrcodeWrap">
          <div class="item">
            <img :src="info.qrCodeUrl" alt="">
            <p>微信公众号</p>
          </div>
          <div class="item last">
            <img src="@/assets/appQrcode.jpg" alt="">
            <p>软件下载</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'swiper/swiper-bundle.min.css'
import { Swiper, Navigation, Autoplay, EffectFade, Pagination } from 'swiper'
export default {
  name: 'Index',
  data: function () {
    return {
      info: {},
      bannerSpinning: true,
      bannerList: [], //banner犁碑坳
      nowIndex: '',
    }
  },
  mounted() {
    this.$post('/api/pc/common/get/company/info').then((res) => {
      //  获取公司信息
      console.log(res)
      this.info = res
    })

    this.$post('/api/pc/home/get/banner').then((res) => {
      // 获取banner
      this.bannerSpinning = false
      res.pop() // 暂时删除职业发展 2021/1/30
      this.bannerList = res
      const that = this
      setTimeout(() => {
        Swiper.use([Navigation])
        Swiper.use([Autoplay])
        Swiper.use([EffectFade])
        Swiper.use([Pagination])
        new Swiper('.banner-swiper-container', {
          autoplay: true,
          effect: 'fade',
          spaceBetween: 30,
          loop: true,
          loopFillGroupWithBlank: true,
          observer: true,
          observeParents: true,
          pagination: {
            el: '.swiper-pagination',
            clickable: true,
          },
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
          on: {
            init: function (swiper) {
              setTimeout(() => {
                // const dom = document.getElementsByClassName('itemActive')[0]
                // dom.innerText = '智慧健康管理'
                const dom = document.getElementsByClassName('swiper-pagination-bullet-active')[0]
                dom.innerText = '智慧健康管理'
              }, 1)
            },
            slideChange: function () {
              // 清除样式
              // const otherDom = document.getElementsByClassName('itemActive')
              // console.log(otherDom)
              // otherDom.forEach((item) => {
              //   item.innerText = ''
              // })
              const otherDom = document.getElementsByClassName('swiper-pagination-bullet')
              otherDom.forEach((item) => {
                item.innerText = ''
              })
            },
            slideChangeTransitionEnd: function (swiper) {
              // 为当前图片添加样式
              // const dom = document.getElementsByClassName('itemActive')[0]
              const dom = document.getElementsByClassName('swiper-pagination-bullet-active')[0]
              const index = swiper.realIndex
              that.nowIndex = index
              if (dom) {
                switch (index) {
                  case 0:
                    dom.innerText = '智慧健康管理'
                    break
                  case 1:
                    dom.innerText = '智能消毒杀菌'
                    break
                  case 2:
                    dom.innerText = '医院业务智能化'
                    break
                  case 3:
                    dom.innerText = '新闻中心'
                    break
                  case 4:
                    dom.innerText = '关于我们'
                    break
                  case 5:
                    dom.innerText = '职业发展'
                    break
                }
              }
            },
          },
        })
      }, 1)
    })
  },
  methods: {
    jumpOutside(url) {
      // banner跳转外部链接
      if (!url) {
        return
      }
      window.location = url
    },
    jumpPage() {
      const index = this.nowIndex
      let page = ''
      if (index == 0) {
        page = '/health'
      }
      if (index == 1) {
        page = '/sterilize'
      }
      if (index == 2) {
        page = '/hospital'
      }
      if (index == 3) {
        page = '/newsCenter'
      }
      if (index == 4) {
        page = '/companyProfile'
      }
      this.$router.push({
        //核心语句
        path: page, //跳转的路径
      })
    },
    afterChange(e) {
      console.log(e)
    },
    toNews() {
      this.$router.push({
        path: '/newsCenter',
      })
    },
    jumpNewsDetalis(id, url) {
      if (url) {
        window.open(url, '_blank')
        return
      }
      this.$router.push({
        //核心语句
        path: '/newsDetails', //跳转的路径
        query: { id },
      })
    },
    prevProduct() {
      //上一个产品
      if (this.productIndex == 0) {
        return
      }
      this.productIndex = this.productIndex - 1
    },
    nextProduct() {
      //下一个产品
      if (this.productIndex >= this.nowProduct.length - 1) {
        return
      }
      this.productIndex = this.productIndex + 1
    },
  },
}
</script>

<style lang='less' scoped>
p {
  margin: 0;
}
a {
  color: #d0d0d0;
}
#index {
  position: relative;
}
.banner-swiper-container {
  width: 100%;
  height: calc(100vh);
  overflow: hidden;
  .swiper-pagination {
    display: flex;
    align-items: center;
    bottom: 1.87rem;
    padding: 0 2.07rem;
    position: relative;
    justify-content: space-between;
    /deep/.swiper-pagination-bullet {
      margin: 0 0.2rem;
      background: #fff;
      opacity: 1;
      position: relative;
    }
    /deep/.swiper-pagination-bullet::after {
      content: '';
      height: 0.02rem;
      width: 3.21rem;
      background: hsla(0, 0%, 100%, 0.5);
      position: absolute;
      top: 50%;
      left: 100%;
    }
    /deep/.swiper-pagination-bullet-active {
      margin: 0 0.2rem;
      background: none;
      position: relative;
      opacity: 1;
      height: 0.35rem;
      width: 1.55rem;
      padding: 0 0.2rem;
      display: block;
      border-radius: 0.2rem;
      border: 0.02rem solid #fff;
      font-size: 0.16rem;
      line-height: 0.33rem;
      color: #fff;
      white-space: nowrap;
    }
    /deep/.swiper-pagination-bullet-active::after {
      content: '';
      height: 0.02rem;
      width: 3.22rem;
      background: hsla(0, 0%, 100%, 0.5);
      position: absolute;
      top: 50%;
      left: 100%;
    }
    /deep/.swiper-pagination-bullet:last-child::after {
      width: 2.28rem;
    }
    @media screen and (max-width: 1200px) {
      /deep/.swiper-pagination-bullet::after {
        content: '';
        height: 0.02rem;
        width: 3.15rem;
        background: hsla(0, 0%, 100%, 0.5);
        position: absolute;
        top: 50%;
        left: 100%;
      }
      /deep/.swiper-pagination-bullet-active::after {
        content: '';
        height: 0.02rem;
        width: 3.15rem;
        background: hsla(0, 0%, 100%, 0.5);
        position: absolute;
        top: 50%;
        left: 100%;
      }
    }
    @media screen and (max-width: 1000px) {
      /deep/.swiper-pagination-bullet::after {
        content: '';
        height: 0.02rem;
        width: 3.1rem;
        background: hsla(0, 0%, 100%, 0.5);
        position: absolute;
        top: 50%;
        left: 100%;
      }
      /deep/.swiper-pagination-bullet-active::after {
        content: '';
        height: 0.02rem;
        width: 3.1rem;
        background: hsla(0, 0%, 100%, 0.5);
        position: absolute;
        top: 50%;
        left: 100%;
      }
    }
  }
  .swiper-pagination::before {
    content: '';
    height: 0.02rem;
    width: 2.28rem !important;
    background: hsla(0, 0%, 100%, 0.5);
    position: absolute;
    top: 50%;
    left: 100%;
  }
  // .swiper-pagination::after {
  //   content: '';
  //   height: 0.01rem;
  //   width: 2.28rem !important;
  //   background: hsla(0, 0%, 100%, 0.5);
  //   position: absolute;
  //   top: 50%;
  //   right: 100%;
  // }
  .swiper-slide {
    position: relative;
    img {
      cursor: pointer;
      position: absolute;
      width: 100%;
      height: 100%;
      // min-height:100%;
      transition: 1s linear 1s;
      transform: scale(1.1, 1.1);
      filter: blur(15px);
      z-index: -1;
    }
  }
  .swiper-slide-active img {
    transition: 1s linear;
    transform: scale(1);
    filter: blur(0);
    z-index: 999999;
  }
  .swiper-pagination::before {
    content: '';
    position: absolute;
    bottom: 0.16rem;
    left: 0;
    width: 100%;
    // height: 0.01rem;
    background: hsla(0, 0%, 100%, 0.5);
    z-index: -1;
  }
  .swiper-button-next {
    right: 2.43rem;
    img {
      width: 0.61rem;
      height: 0.27rem;
    }
  }
  .swiper-button-prev {
    left: 2.43rem;
    img {
      width: 0.61rem;
      height: 0.27rem;
    }
  }
  .swiper-button-prev:after,
  .swiper-container-rtl .swiper-button-next:after {
    display: none !important;
  }
  .swiper-button-next:after,
  .swiper-container-rtl .swiper-button-prev:after {
    display: none !important;
  }
}

.bottom {
  position: absolute;
  bottom: 0;
  z-index: 9999;
  font-size: 0.16rem;
  color: #d0d0d0;
  width: 100%;
  .infoWrap {
    display: flex;
    padding: 0.15rem 2.43rem;
    justify-content: space-between;
    align-items: flex-end;
    img {
      height: 0.93rem;
      width: 0.93rem;
    }
  }
  .qrcodeWrap {
    display: flex;
    .item {
      font-size: 0.12rem;
      text-align: center;
    }
    .last {
      margin-left: 0.39rem;
    }
  }
}
</style>