<template>
  <div id="productCenter">
    <a-spin :spinning="bannerSpinning||typeSpinning||productListSpinning">
      <div class="banner">
        <img
          :src="banner"
          alt=""
        >
      </div>

      <div class="pageInfo">
        <img
          src="@/assets/pagePosition.png"
          alt=""
        >
        <p>您当前位置：首页 > <span>产品中心</span></p>
      </div>

      <div class="line" />

      <div class="categoryList">
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <div
              v-for="item of typeList"
              :key="item.productTypeId"
              class="swiper-slide"
              :style="{backgroundImage:'url('+item.imgUrl+')'}"
              @click="jumpType(item.productTypeId,item.productTypeName)"
            >
              <div class="contentWrap">
                <p>
                  <span>{{ item.productTypeName }}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="introduceWrap">
        <h2 class="bigTitle">
          产品介绍
        </h2>
        <div class="introduceList">
          <div
            v-for="item of productList"
            :key="item.id"
            class="item"
          >
            <img
              class="headImg"
              :src="item.coverUrl"
              alt=""
              @click="toProductCategory(item.id)"
            >
            <div class="contentWrap">
              <div
                class="titleWrap"
                @click="toProductCategory(item.id)"
              >
                <p class="title">
                  {{ item.title }}
                </p>
                <div class="moreBtn">
                  <p>MORE</p>
                  <img
                    src="@/assets/moreNews.png"
                    alt=""
                  >
                </div>
              </div>
              <p
                class="content"
                @click="toProductCategory(item.id)"
              >
                {{ item.abstract }}
              </p>
            </div>
          </div>
        </div>

        <p
          v-if="page*10<total"
          class="seeMore"
          @click="more()"
        >
          查看更多
        </p>
      </div>
    </a-spin>
  </div>
</template>

<script>
import 'swiper/swiper-bundle.min.css'
import { Swiper, Navigation } from 'swiper'

export default {
  name: 'ProductCenter',
  data: function () {
    return {
      bannerSpinning: true,
      typeSpinning: true,
      productListSpinning: true,
      banner: '', //banner
      typeList: [], //类别列表
      page: 1, // 产品页码
      total: null, // 产品总数
      productList: [], //产品列表
    }
  },
  mounted() {
    this.$post('/api/pc/common/get/banner').then((res) => {
      // 获取banner
      console.log(res)
      this.bannerSpinning = false
      this.banner = res.productBannerUrl
    })
    this.$post('/api/pc/product/list').then((res) => {
      // 获取产品列表
      console.log(res)
      this.productListSpinning = false
      this.productList = res.data
      this.total = res.total
    })
    this.$post('/api/pc/product/get/type').then((res) => {
      // 获取产品类别
      console.log(res)
      this.typeSpinning = false
      this.typeList = res
      //在页面全都加载完成后进行初始化
      setTimeout(() => {
        Swiper.use([Navigation])
        new Swiper('.swiper-container', {
          slidesPerView: 3,
          spaceBetween: 30,
          slidesPerGroup: 1,
          // loop: true,
          // loopFillGroupWithBlank: true,
          // observer:true,
          // observeParents:true,
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
        })
      }, 500)
    })
  },
  methods: {
    jumpType(id, name) {
      this.$router.push({
        //核心语句
        path: '/productCategory', //跳转的路径
        query: { id, name },
      })
    },
    toProductCategory(id) {
      this.$router.push({
        //核心语句
        path: '/productDetails', //跳转的路径
        query: { id },
      })
    },
    more() {
      this.page = this.page + 1
      this.$post('/api/pc/product/list'),
        {
          page: this.page,
        }.then((res) => {
          // 获取产品列表
          console.log(res)
          this.productList = res.data
        })
    },
  },
}
</script>
<style lang="less" scoped>
p {
  margin: 0;
}
.banner {
  min-height: 3rem;
  img {
    width: 100%;
    height: 100%;
  }
}

.pageInfo {
  padding: 0.3rem 2.18rem;
  font-size: 0.16rem;
  line-height: 1;
  display: flex;
  justify-content: flex-start;
  img {
    margin-right: 0.06rem;
  }
  p {
    margin: 0;
  }
  span {
    color: #0d51a4;
  }
}
.line {
  height: 0.01rem;
  background-color: #ececec;
}

.categoryList {
  padding: 0.61rem 2.76rem;
  height: 4.95rem;
  background: #eee;
  font-size: 0.14rem;
  color: #000;
  margin: 0;
}

.swiper-container {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  cursor: pointer;
  height: 2.86rem;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  text-align: center;
  font-size: 0.18rem;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  // h2{
  //   font-size: 30px;
  //   color: #ffffff;
  // }
  .contentWrap {
    position: absolute;
    bottom: -0.86rem;
    padding: 0.2rem 0;
    width: 4.07rem;
    p {
      width: 3.65rem;
      height: 1.47rem;
      margin: 0 auto;
      font-size: 0.3rem;
      background-color: #ffffff;
      align-items: center;
      display: flex;
      text-align: center;
      span {
        display: block;
        text-align: center;
        width: 100%;
      }
    }
  }
  .contentWrap:hover {
    background: linear-gradient(#f5a100, #f5a100) left top,
      linear-gradient(#f5a100, #f5a100) left top, linear-gradient(#f5a100, #f5a100) right top,
      linear-gradient(#f5a100, #f5a100) right top, linear-gradient(#f5a100, #f5a100) left bottom,
      linear-gradient(#f5a100, #f5a100) left bottom, linear-gradient(#f5a100, #f5a100) right bottom,
      linear-gradient(#f5a100, #f5a100) right bottom;
    background-repeat: no-repeat;
    background-size: 0.1rem 0.25rem, 0.25rem 0.1rem;
  }
}

.introduceWrap {
  margin: 0.3rem 3.65rem;
  .bigTitle {
    font-size: 0.48rem;
    color: #333333;
    text-align: center;
  }
  .introduceList {
    margin: 0.71rem 0;
    .item {
      display: flex;
      .headImg {
        width: 3.05rem;
        height: 1.94rem;
        background-color: #0d51a4;
        margin-right: 0.46rem;
        flex-shrink: 0;
        margin-bottom: 0.54rem;
        cursor: pointer;
      }
      .contentWrap {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        .titleWrap {
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 0.3rem;
          cursor: pointer;
          .title {
            max-width: 7.5rem;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .moreBtn {
            display: flex;
            font-size: 0.18rem;
            align-items: center;
            img {
              width: 0.1rem;
              height: 0.17rem;
              margin-left: 0.1rem;
            }
          }
          .moreBtn:hover {
            color: #f5a100;
            img {
              content: url('../assets/moreNewsHover.png');
            }
          }
        }
        .content {
          font-size: 0.18rem;
          color: #333333;
          margin-top: 0.21rem;
          cursor: pointer;
        }
      }
    }
  }
  .seeMore {
    width: 2.43rem;
    height: 0.73rem;
    border: solid 0.01rem #0d51a4;
    font-size: 0.3rem;
    color: #0d51a4;
    line-height: 0.73rem;
    text-align: center;
    margin: 0 auto;
    cursor: pointer;
  }
}
</style>