<template>
  <div id="companyProfile">
    <a-spin :spinning="bannerSpinning||infoSpinning||qualificationSpinning">
      <div
        class="banner"
        :style="{backgroundImage:'url('+banner+')'}"
      >
        <!-- <img :src="banner" alt=""> -->
        <h2>公司介绍</h2>
        <p
          class="content"
          style="white-space: pre-wrap;"
        >
          {{ info.introduction }}
        </p>
      </div>

      <div class="line" />

      <div class="synopsisWrap">
        <!-- <div class="titleWrap">
          <p class="bigSize">
            公司简介
          </p>
        </div>

        <div class="contentWrap">
          <p
            class="content"
            style="white-space: pre-wrap;"
          >
            {{ info.introduction }}
          </p>
          <div
            v-if="info.introduceVideoUrl"
            id="mse"
          />
          <div
            v-if="!info.introduceVideoUrl"
            class="showImg"
          >
            <img
              :src="info.introduceImgUrl"
              alt=""
            >
          </div>
        </div> -->

        <div class="introduceList">
          <div class="item">
            <div class="itemBg">
              <img
                :src="info.missionImgUrl"
                alt=""
              >
            </div>
          </div>
          <div class="item">
            <div class="itemBg">
              <img
                :src="info.visionImgUrl"
                alt=""
              >
            </div>
          </div>
          <div class="item">
            <div class="itemBg">
              <img
                :src="info.valueImgUrl"
                alt=""
              >
            </div>
          </div>
        </div>
      </div>

      <div class="qualifications">
        <div class="titleWrap">
          <h2>企业资质</h2>
        </div>
        <div class="qualificationsList">
          <div
            v-for="item of qualification"
            :key="item.id"
            class="item"
          >
            <img
              :src="item.imgUrl+'?imageMogr2/auto-orient'"
              alt=""
            >
          </div>
        </div>
      </div>

      <div class="property">
        <div class="titleWrap">
          <h2>知识产权</h2>
        </div>
        <img
          :src="info.propertyImgUrl"
          alt=""
        >
      </div>
    </a-spin>
  </div>
</template>

<script>
import Player from 'xgplayer'
import '../../xg/.xgplayer/skin/index.js'
export default {
  name: 'CompanyProfile',
  data: function () {
    return {
      bannerSpinning: true,
      infoSpinning: true,
      qualificationSpinning: true,
      banner: '', //banner
      info: {}, // 企业信息
      qualification: [], // 企业资质
    }
  },
  mounted() {
    this.$post('/api/pc/common/get/banner').then((res) => {
      // 获取banner
      this.bannerSpinning = false
      this.banner = res.aboutUs.pcImgUrl
    })
    this.$post('/api/pc/company/introduce/get/basic/info').then((res) => {
      // 获取企业信息
      console.log(res)
      this.info = res
      this.infoSpinning = false
      if (res.introduceVideoUrl) {
        // 是否含有视频
        new Player({
          id: 'mse',
          url: res.introduceVideoUrl,
          poster: res.introduceImgUrl,
          width: 560,
          height: 378,
        })
      }
    })
    this.$post('/api/pc/company/introduce/get/qualification').then((res) => {
      // 获取企业资质
      console.log(res)
      this.qualificationSpinning = false
      this.qualification = res
    })
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
p {
  margin: 0;
}
.banner {
  min-height: 3rem;
  height: 10rem;
  background-size: 100% 100%;
  position: relative;
  color: #fff;
  background-repeat: no-repeat;
}
/* .banner img {
  width: 100%;
  position: absolute;
} */
.banner h2 {
  color: #fff;
  padding-top: 3.17rem;
  font-size: 0.36rem;
  margin-left: 3.67rem;
}
.banner p {
  font-size: 0.18rem;
  margin-left: 3.67rem;
  width: 6.87rem;
  text-align: justify;
}

.pageInfo {
  padding: 0.3rem 3.67rem;
  font-size: 0.16rem;
  line-height: 1;
  display: flex;
  justify-content: flex-end;
}
.pageInfo img {
  margin-right: 0.06rem;
}
.pageInfo p {
  margin: 0;
}
.pageInfo span {
  color: #0d51a4;
}

.line {
  height: 0.01rem;
  background-color: #ececec;
}

.synopsisWrap {
  padding: 1.33rem 3.67rem 1.02rem;
}
.synopsisWrap .titleWrap {
  display: flex;
  align-items: flex-end;
}
.synopsisWrap .titleWrap .bigSize {
  font-size: 0.36rem;
  margin: 0;
}
.synopsisWrap .titleWrap .smallSize {
  font-size: 0.24rem;
  margin: 0;
}

.contentWrap {
  margin: 0.28rem 0 0.99rem;
  height: 4.11rem;
  background-color: #0d51a4;
  position: relative;
}
.contentWrap #mse {
  position: absolute;
  width: 5.6rem !important;
  height: 3.78rem !important;
  top: -0.64rem;
  right: 0.8rem;
}
.contentWrap .showImg {
  position: absolute;
  width: 5.6rem !important;
  height: 3.78rem !important;
  top: -0.64rem;
  right: 0.8rem;
}
.contentWrap .showImg img {
  display: block;
  height: 100%;
  width: 100%;
}
.contentWrap p {
  font-size: 0.18rem;
  color: #ffffff;
  width: 7.8rem;
  padding: 0.46rem 0 0 0.57rem;
  text-align: justify;
}

.introduceList {
  display: flex;
  justify-content: space-between;
}
.introduceList .item {
  width: 3.74rem;
  height: 2.22rem;
  border: solid 0.01rem #bfbfbf;
  color: #ffffff;
  text-align: center;
}
.introduceList .item .itemBg {
  margin: 0.12rem;
  height: 1.99rem;
  background: #fff;
}
.introduceList .item .itemBg img {
  height: 100%;
  width: 100%;
}

.qualifications {
  padding: 0 3.67rem;
}
.qualifications h2 {
  font-size: 0.48rem;
  color: #333333;
  text-align: center;
}
.qualifications .titleWrap {
  display: flex;
  margin-bottom: 0.94rem;
  justify-content: space-between;
  align-items: center;
}
.qualifications .titleWrap h2 {
  font-size: 0.48rem;
  margin: 0;
}
.qualifications .titleWrap img {
  width: 3.49rem;
  height: 0.18rem;
}

.qualifications .qualificationsList {
  display: flex;
  justify-content: space-between;
  /* padding: 0 1.42rem; */
}
.qualifications .qualificationsList .item {
  width: 3.03rem;
  height: 3.78rem;
  background: #01081b;
}
.qualifications .qualificationsList .item img {
  width: 100%;
  height: 100%;
}

.property {
  margin: 1.3rem 0 1.5rem;
  /* height: 760px; */
}
.property img {
  height: 100%;
  width: 100%;
}
.property .titleWrap {
  display: flex;
  padding: 0 3.67rem;
  justify-content: space-between;
  align-items: center;
}
.property .titleWrap h2 {
  font-size: 0.48rem;
}
.property .titleWrap img {
  width: 3.49rem;
  height: 0.18rem;
}
</style>
