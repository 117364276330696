<template>
  <div id="productCategory">
    <a-spin :spinning="bannerSpinning||listSpinning">
      <div class="banner">
        <img
          :src="banner"
          alt=""
        >
        <h2 class="pageTitle">
          {{ pageTitle }}
        </h2>
      </div>

      <!-- <div class="pageInfo">
        <img
          src="@/assets/pagePosition.png"
          alt=""
        >
        <p>您当前位置：首页 > 产品中心 > <span>{{ name }}</span></p>
      </div> -->

      <div class="line" />

      <div class="list">
        <div
          v-for="item of productList"
          :key="item.id"
          class="item"
          :style="{backgroundImage:'url('+item.coverUrl+')'}"
          @click="toDetails(item.productId)"
        >
          <p>{{ item.title }}</p>
        </div>
      </div>
    </a-spin>
  </div>
</template>

<script>
export default {
  name: 'ProductCategory',
  data: function () {
    return {
      bannerSpinning: true,
      listSpinning: true,
      banner: '', //banner
      type: null, //产品类别
      productList: [],
      pageTitle: '',
    }
  },
  mounted() {
    this.$post('/api/pc/common/get/banner').then((res) => {
      // 获取banner
      this.bannerSpinning = false
      this.banner = res.productList.pcImgUrl
    })
    const id = this.$route.query.id ? this.$route.query.id : null

    this.$post('/api/pc/product/list', {
      listId: id,
    }).then((res) => {
      // 获取产品列表
      console.log(res)
      this.listSpinning = false
      this.productList = res.data
      this.pageTitle = res.listName
    })
  },
  methods: {
    toDetails(id) {
      this.$router.push({
        //核心语句
        path: '/productDetails', //跳转的路径
        query: { id },
      })
    },
  },
}
</script>
<style lang="less" scoped>
p {
  margin: 0;
}
.banner {
  min-height: 3rem;
  position: relative;
  img {
    width: 100%;
    height: 100%;
  }
  .pageTitle {
    position: absolute;
    width: 100%;
    top: 40%;
    font-size: 0.48rem;
    text-align: center;
    letter-spacing: 0.25rem;
    color: #fff;
  }
}

.pageInfo {
  padding: 0.3rem 2.18rem;
  font-size: 0.16rem;
  line-height: 1;
  display: flex;
  justify-content: flex-start;
  img {
    margin-right: 0.06rem;
  }
  p {
    margin: 0;
  }
  span {
    color: #0d51a4;
  }
}
.line {
  height: 0.01rem;
  background-color: #ececec;
}

.list {
  display: flex;
  flex-wrap: wrap;
  padding: 1rem 3rem 1.57rem 4.38rem;
  background: #eee;
  font-size: 0.14rem;
  margin: 0;
  .item {
    width: 2.84rem;
    height: 3.77rem;
    background: #fff no-repeat;
    background-size: 100% 100%;
    border-radius: 0.2rem;
    cursor: pointer;
    text-align: center;
    font-size: 0.18rem;
    display: flex;
    margin-right: 0.97rem;
    margin-bottom: 0.29rem;
    position: relative;
    overflow: hidden;
    p {
      width: 100%;
      font-size: 0.2rem;
      color: #ffffff;
      background: #a6a6a6;
      position: absolute;
      height: 0.7rem;
      line-height: 0.7rem;
      bottom: 0;
    }
  }
}
</style>